import { useState, useEffect } from 'react';
import { themes, ThemeType, defaultTheme } from '../styles/theme';

export function useTheme() {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>(defaultTheme);
  const setTheme = (theme: ThemeType) => {
    const root = document.documentElement;
    const selectedTheme = themes[theme];

    root.style.setProperty('--theme-primary', selectedTheme.primary);
    root.style.setProperty('--theme-secondary', selectedTheme.secondary);
    root.style.setProperty('--theme-magic', selectedTheme.magic);
    root.style.setProperty('--theme-background', selectedTheme.background);
    root.style.setProperty('--theme-text-primary', selectedTheme.text.primary);
    root.style.setProperty('--theme-text-secondary', selectedTheme.text.secondary);
    root.style.setProperty('--theme-gradient-start', selectedTheme.gradient.start);
    root.style.setProperty('--theme-gradient-end', selectedTheme.gradient.end);

    setCurrentTheme(theme);
  };

  useEffect(() => {
    setTheme(currentTheme);
  }, [currentTheme]);

  return { currentTheme, setTheme };
} 