import { useEffect, useRef } from 'react';

export default function Video({ currentTheme }: { currentTheme: string }) {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const isPlayingRef = useRef(false);
  
    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      video.pause(); // Always pause the video initially
  
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const maxScroll = document.body.scrollHeight - window.innerHeight;
        const scrollPercentage = scrollPosition / maxScroll;
        const videoDuration = video.duration || 0;
        
        // If we're at the bottom of the page
        if (scrollPercentage > 0.99) {
          if (!isPlayingRef.current) {
            isPlayingRef.current = true;
            video.currentTime = 0; // Reset to start
            video.play(); // Start playing
          }
        } else {
          isPlayingRef.current = false;
          video.pause();
          // Normal scroll behavior
          const seekTime = scrollPercentage * videoDuration;
          video.currentTime = seekTime;
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
        isPlayingRef.current = false;
        video.pause();
      };
    }, []);
  
    return (
      <video
        className="girlturnVideo"
        ref={videoRef}
        src="/girlturn_white.mp4"
        muted
        autoPlay
        loop
        style={{
          filter: currentTheme === 'red' ? 'hue-rotate(250deg)' : 'none'
        }}
      />
    );
}