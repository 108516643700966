import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ComingSoon } from './pages/ComingSoon';
import './App.css';
import { Canvas } from '@react-three/fiber'
import MetaBalls from './components/MetaBalls'
import FlickerText from './components/FlickerText'
import Mirror from './components/Mirror'
import Head from './components/Head'
import { useState, useEffect, useRef } from 'react';
import { Items } from './components/items/Items'
import { Phone } from './components/Phone'
import { AsciiRenderer, Environment } from '@react-three/drei'
import { FallingPhones } from './components/FallingPhones'
import { Physics } from '@react-three/rapier'
import { Bloom, Pixelation, Scanline } from '@react-three/postprocessing'
import { EffectComposer } from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'
import { useTheme } from './hooks/useTheme'
import { ThemeProvider } from './context/ThemeContext'
import { Zap, Radiation, Menu, X, BookOpen, Twitter, Send, Github, LockKeyhole, Target, ArchiveRestore, Flower, Fingerprint, Vote, Globe, Cpu, Brain, Bot, Glasses, Dna, Layers, Atom, Shirt } from 'lucide-react';
import TypeWriter from './components/TypeWriter';
import MagneticButton from './components/MagneticButton';
import WarpSpeed from './components/WarpSpeed';
import { NotFound } from './pages/NotFound';
import Goddess from './components/Goddess';
import Video from './components/Video';
import Metaballs2D from './components/MetaBalls2D';
import Blob from './components/Blob';
import MetaBallsSensor from './components/MetaBallsSensor';

interface ProjectCardProps {
  number: number;
  title: string;
  description: string;
}

function ProjectCard({ number, title, description }: ProjectCardProps) {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      className={`project-card ${isFlipped ? 'is-flipped' : ''}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="project-card-inner">
        <div className="project-card-front">
          <span className="card-number">{number.toString().padStart(2, '0')}</span>
          <h3>{title}</h3>
          <div className="project-image">
            <Items number={number} />
          </div>
        </div>
        <div className="project-card-back">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

function FAQItem({ question, answer }: { question: string; answer: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button
        className={`faq-question ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <span className="faq-icon">{isOpen ? '−' : '+'}</span>
      </button>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        {answer}
      </div>
    </div>
  );
}

function DeSciApp() {
  const teamSectionRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const { currentTheme, setTheme } = useTheme();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  useEffect(() => {
    console.log(
      "%cHEY PSST%c You need a cooler website than this? contact ux on X: %c@DeSciSunny",
        "font-weight: bold; font-size: 16px; color: cyan;", // Style for "HEY PSST"
        "font-weight: bold; font-size: 16px;", // Style for the rest of the text
        "font-weight: bold; font-size: 16px; color: tomato;" // Style for "@DeSciSunny"
      );
      console.image = function(url, height = 100) {
          const image = new Image();
          image.crossOrigin='anonymous';
          image.onload = function() {
              // build a data url
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.height = height || image.naturalHeight;
              canvas.width = canvas.height * image.naturalWidth / image.naturalHeight;
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
              const dataUrl = canvas.toDataURL();
              const style = [
                  'font-size: 1px;',
                  `padding: ${canvas.height}px ${canvas.width}px;`,
                  `background: url(${dataUrl}) no-repeat;`,
                  'background-size: contain;'
              ].join(' ');
              console.log('%c ', style);
          };
          image.src = url;
      };
      console.image('https://fringe.tech/icon.png');
  }, []);

  const handleThemeSwitch = () => {
    const newTheme = currentTheme === 'green' ? 'red' : 'green';
    setTheme(newTheme);
  };

  const handleMenuToggle = () => {
    requestAnimationFrame(() => {
      setIsMobileMenuOpen(prev => !prev);
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (teamSectionRef.current) {
      observer.observe(teamSectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isVisible || !teamSectionRef.current || !circleRef.current) return;

      const section = teamSectionRef.current;
      const circle = circleRef.current;

      const sectionRect = section.getBoundingClientRect();
      const scrollProgress = (window.innerHeight - sectionRect.top) / (window.innerHeight + sectionRect.height);
      const clampedProgress = Math.max(0, Math.min(1, scrollProgress));

      const translateX = clampedProgress * (window.innerWidth + 200) - 100; // 200 for extra overflow
      circle.style.transform = `translateX(${translateX}px) translateY(-50%)`;
    };

    if (isVisible) {
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial position
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isVisible]);

  return (
    <div className="app-container">
      <Video currentTheme={currentTheme} />
      <div className="canvas-container">
        <Canvas
          dpr={[1, 1.5]}
          camera={{ position: [0, 0, 5], fov: 25 }}
          gl={{
            alpha: true,
            antialias: true,
            preserveDrawingBuffer: true
          }}
          style={{
            height: '100vh',
            background: '#0a0a0a'
          }}
        >
          <ambientLight intensity={1.5} />
          <directionalLight position={[2, 2, 5]} intensity={1.5} />
          <Environment preset="city" />
          {/*
          <Image
            scale={0.4}
            position={[.5,-.32,0]}
            url="/greenGoddessLogo-removebg-preview.png"
          />
           */}
{/*           

            <Blob position={[0,0.4,0]}/>

*/}
<MetaBallsSensor currentTheme={currentTheme} /> 
        </Canvas>
      </div>

      <div className="canvas-overlay">
        <header className="header">
          <div className="logo-text">
            <img className="logo-img" src="/fringe_protocol_white_logo.png" alt="DeSci World" />
            <p className="dao-text">Decentralized Science</p>
          </div>
          <nav>
            <ul>
              <li><a href="#what">WHAT</a></li>
              <li><a href="#how">HOW</a></li>
              <li><a href="#why">WHY</a></li>
              <li><a href="#faq">FAQ</a></li>
              <li><a href="#portal" className="launch-btn">LAUNCH PORTAL<span className="soon-text">soon</span></a></li>
            </ul>
          </nav>
          {/* Theme switcher (hidden on mobile) */}
          <button
            className="theme-switch-btn"
            onClick={handleThemeSwitch}
            title={`Switch to ${currentTheme === 'green' ? 'red' : 'green'} theme`}
          >
            <Radiation
              size={20}
              className={`theme-icon ${currentTheme}`}
            />
          </button>
        </header>

        {/* Mobile menu button */}
        <button
          className="mobile-menu-btn"
          onClick={handleMenuToggle}
          aria-label="Toggle menu"
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Mobile menu */}
        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="#how" onClick={() => setIsMobileMenuOpen(false)}>HOW IT WORKS</a></li>
            <li><a href="#why" onClick={() => setIsMobileMenuOpen(false)}>WHY FRINGE</a></li>
            <li><a href="#faq" onClick={() => setIsMobileMenuOpen(false)}>FAQ</a></li>
            <li><a href="#portal" className="launch-btn" onClick={() => setIsMobileMenuOpen(false)}>LAUNCH PORTAL</a></li>
          </ul>
        </div>

        <main className="hero-section">
          <div className="hero-content">
            <h1 className="hero-title">
              <span className="small-text">The</span>
              <span className="main-text">FRINGE</span>
              <span className="small-text">of</span>
              <div className="gradient-text">
                <span className="highlight-text">Engineering &</span>
                <TypeWriter
                  words={['Science', 'Magic', 'Innovation', 'Future']}
                  typingSpeed={20}
                  deletingSpeed={30}
                  delayBetweenWords={2000}
                />
              </div>
            </h1>

            <div className="cta-container">
              <div className="cta-buttons">
                <MagneticButton
                  href="https://t.me/FringeProtocol"
                  className="cta-button telegram"
                >
                  Telegram
                </MagneticButton>

                <MagneticButton
                  href="https://x.com/Fringe_Protocol"
                  className="cta-button x"
                >
                  X
                </MagneticButton>

                <MagneticButton
                  href=""
                  className="cta-button gitbook"
                >
                  Gitbook<span className="soon-text">soon</span>
                </MagneticButton>
              </div>
            </div>

            <p className="hero-subtitle">
              The edge of possibility; decentralized, onchain and ready to be explored
            </p>
          </div>
          <div className="scroll-indicator">
            SCROLL
            <span className="dot">•</span>
            DOWN
          </div>
        </main>

        <section className="about-section" id="what">
          <div className="about-content">
            <div className="about-grid">
              <div className="about-column">
                <h2>What is Fringe Protocol?</h2>
                <div className="about-text">
                  <p>
                  Fringe Protocol discovers and provides opportunities for the community to fund engineering and applied science research. Transforming visionary ideas into real world technologies while rewarding its global network of supporters in the process.
                  </p>
                  <p className="highlight">
                    Join Fringe Protocol by contributing your expertise to fuel the next wave of
                    scientific progress.
                  </p>
                </div>
              </div>

              <div className="about-column">
                <h2>A New Era of Open Innovation</h2>
                <div className="about-text">
                  <p>
                  Holding $Fringe allows supporters to be exposed to a range of up and coming projects and research, gaining early access to tech and products that will become the new paradigm in many different sectors. Building, funding and owning our new future-transparently.
                  </p>
                  <div className="about-features">
                    <div className="feature">
                      <span className="feature-icon"><Atom size={44} color="lime" style={{ stroke: "url(#blue-gradient)" }}/></span>
                      <span>Pitch Research and Ideas</span>
                    </div>
                    <div className="feature">
                      <span className="feature-icon"><Vote size={44} color="lime" style={{ stroke: "url(#blue-gradient)" }}/></span>
                      <span>Curated Projects</span>
                    </div>
                    <div className="feature">
                      <span className="feature-icon"><Globe size={44} color="lime" style={{ stroke: "url(#blue-gradient)" }}/></span>
                      <span>Transparent Funding</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="process-section" id="how">
          <svg width="0" height="0">
            <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
            <React.Fragment>
                <stop stopColor={currentTheme === 'red' ? "#ED6D6D" : "#DEED6D"} offset="0%" />
                <stop stopColor={currentTheme === 'red' ? "#7B00FF" : "#9DFF00"} offset="100%" />
            </React.Fragment>
            </linearGradient>
          </svg>
          <div className="process-content">
            <h2>How It Works</h2>
            <div className="process-steps">
              <div className="process-step">
                <div className="step-number">01</div>
                <div className="step-content">
                  <div className="step-icon"><Target size={44} color="lime" style={{ stroke: "url(#blue-gradient)" }}/></div>
                  <h3>Submit Proposals</h3>
                  <p>Researchers Pitch their ideas as we as curators find interesting projects to add for the community.</p>
                </div>
                <div className="step-line"></div>
              </div>

              <div className="process-step">
                <div className="step-number">02</div>
                <div className="step-content">
                  <div className="step-icon"><ArchiveRestore size={44} color="lime" style={{ stroke: "url(#blue-gradient)" }}/></div>
                  <h3>Curated Projects</h3>
                  <p>Projects are then curated and opportunities presented to the community.</p>
                </div>
                <div className="step-line"></div>
              </div>

              <div className="process-step">
                <div className="step-number">03</div>
                <div className="step-content">
                  <div className="step-icon"><Flower size={44} color="lime" style={{ stroke: "url(#blue-gradient)" }}/></div>
                  <h3>Sustainable Funding</h3>
                  <p>Projects that achieve funding goals are tokenized and tokens added to $FRINGE treasury, liquidity pool, airdropped to holders and distributed to funders.</p>
                </div>
                <div className="step-line"></div>
              </div>

              <div className="process-step">
                <div className="step-number">04</div>
                <div className="step-content">
                  <div className="step-icon"><Fingerprint size={44} color="lime" style={{ stroke: "url(#blue-gradient)" }}/></div>
                  <h3>Ownership Preserved</h3>
                  <p>Researchers maintain their ownership over the IP according to the terms of the funding process while receiving funding they need to conduct the research or complete the product.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="manifesto-section" id="why">
          <div className="manifesto-content">
            <h2>Why Join Fringe Protocol?</h2>
            <div className="manifesto-grid">
              <div className="manifesto-text">
                <div className="card-grid">
                  <div className="manifesto-card">
                    <span className="card-number">01</span>
                    <h3>Empower Innovators</h3>
                    <p>
                    Directly support scientists and engineers in solving real world problems and creating real world products.
                    </p>
                  </div>

                  <div className="manifesto-card">
                    <span className="card-number">02</span>
                    <h3>Decentralized Science</h3>
                    <p>
                    Influence the discourse on science and engineering by funding the project and research you want to see exist in our reality!
                    </p>
                  </div>

                  <div className="manifesto-card">
                    <span className="card-number">03</span>
                    <h3>Sustainable Impact</h3>
                    <p>
                    Participate in a funding and tokenomics model that continuously fuels innovation and rewards users for it.
                    </p>
                  </div>

                  <div className="manifesto-card">
                    <span className="card-number">04</span>
                    <h3>Own the Future</h3>
                    <p>
                    Gain early access to these groundbreaking technologies and share in their success through IP tokenization.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mirror-scene">
                <Mirror currentTheme={currentTheme} />
              </div>
            </div>
          </div>
        </section>

        {/* New Team Section */}
        <section ref={teamSectionRef} className="team-section hide">
          <div ref={circleRef} className="floating-circle" />
          <div className="team-content">
            <h2>Our Degens</h2>
            <div className="team-grid">
              <div className="team-member">
                <div className="member-image">
                  <div className="image-placeholder">
                    <Head color="red" rotation={[0, 0.6, 0]} />
                  </div>
                </div>
                <h3>Dr. Phypto</h3>
                <p className="title">Quantum Computing Lead</p>
                <p className="bio">Pioneer in quantum entanglement and its applications in decentralized systems.</p>
              </div>

              <div className="team-member">
                <div className="member-image">
                  <div className="image-placeholder">
                    <Head color="blue" rotation={[0, 0.5, 0]} />
                  </div>
                </div>
                <h3>The Oracle</h3>
                <p className="title">Blockchain Architect</p>
                <p className="bio">Specializes in creating secure, scalable decentralized infrastructures.</p>
              </div>

              <div className="team-member">
                <div className="member-image">
                  <div className="image-placeholder">
                    <Head color="lime" rotation={[0, 0.4, 0]} />
                  </div>
                </div>
                <h3>Dr. Sunny Swiss</h3>
                <p className="title">100X Dev</p>
                <p className="bio">Leading breakthrough research in cross-disciplinary scientific collaboration.</p>
              </div>

              <div className="team-member">
                <div className="member-image">
                  <div className="image-placeholder">
                    <Head color="hotpink" rotation={[0, 0.2, 0]} />
                  </div>
                </div>
                <h3>Fireandwhiskey🥃🔥</h3>
                <p className="title">AI Ethics Officer</p>
                <p className="bio">Ensuring responsible development of AI in scientific research.</p>
              </div>

              <div className="team-member">
                <div className="member-image">
                  <div className="image-placeholder">
                    <Head color="orange" rotation={[0, 0.1, 0]} />
                  </div>
                </div>
                <h3>Dr. Meme de Lord Grugo</h3>
                <p className="title">Community Lead</p>
                <p className="bio">Building bridges between traditional science and DeSci communities.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="projects-section hide">
          <div className="projects-content">
            <h2>Funded Projects</h2>
            <div className="projects-grid">
              <ProjectCard
                number={1}
                title="IP is AI's DNA"
                description="From voice models to training data, to data-driven insights, everything AI agents ingest and create is a form of intellectual property."
              />
              <ProjectCard
                number={2}
                title="Neural Networks"
                description="Advanced neural architectures designed specifically for scientific discovery."
              />
              <ProjectCard
                number={3}
                title="Quantum Bridge"
                description="A revolutionary interface between classical and quantum systems, enabling seamless integration of quantum computing into existing research workflows."
              />
              <ProjectCard
                number={4}
                title="BioSynth"
                description="AI-driven platform for synthetic biology design, accelerating the development of new biological systems and materials."
              />
            </div>
          </div>
        </section>

        <section className="portal-section" id="portal">
          <WarpSpeed currentTheme={currentTheme} />
          <div className="portal-content">
            <div className="portal-text">
              <h2>Portal</h2>
              <h3>Enabling Innovation</h3>
              <p>
                Explore decentralized research funding and engineering breakthroughs through
                the Fringe Portal, built with support from industry leaders.
              </p>
              <button className="portal-launch-btn">
                Coming Soon
                <span className="portal-btn-glow"></span>
              </button>
            </div>
          </div>
        </section>

        <section className="faq-section" id="faq">
          <div className="faq-content">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-grid">
              <FAQItem
                question="What is Fringe Protocol?"
                answer="Fringe Protocol is a foundation driven DeSci protocol that funds research and projects in engineering and applied science sectors."
              />
              <FAQItem
                question="How does Fringe Protocol Fund Research and Projects?"
                answer="We fund research and projects by acting as a platform that offers the opportunity and access to the community to fund the future they want to see."
              />
              <FAQItem
                question="What types of Projects does Fringe Protocol Fund?"
                answer={
                  <>
                    <p>Fringe Protocol focuses on cutting-edge projects in all fields, to name a few:</p>
                    <ul>
                      <li> 
                        <Zap
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Fusion Energy
                      </li>
                      <li>
                        <Cpu
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Quantum Computing
                      </li>
                      <li>
                        <Brain
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Cybernetics
                      </li>
                      <li>
                        <Bot
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Advanced Robotics
                      </li>
                      <li>
                        <Glasses
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Immersive Technologies
                      </li>
                      <li>
                        <Dna
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Trans-humanism
                      </li>
                      <li>
                        <Layers
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Advanced Materials
                      </li>
                      <li>
                        <Atom
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Nanotechnology
                      </li>
                      <li>
                        <Shirt
                          size={20}
                          className={`theme-icon ${currentTheme}`}
                        /> Smart Textiles
                      </li>
                    </ul>
                  </>
                }
              />
              <FAQItem
                question="Does Fringe Protocol have a Token?"
                answer="Fringe Protocol does not have a token yet. Be careful of scammers claiming otherwise. Always check official links for updates."
              />
              <FAQItem
                question="How does Fringe Protocol handle Intellectual Property (IP)?"
                answer="Researchers retain full ownership of their IP however it is tokenized as part of a IPT allowing the protocol and community to share in commercial success as well as access to the products, if any."
              />
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="contact-content">
            <div className="contact-info">
              <h2>Let's Connect</h2>
              <p>
                Ready to explore the future of AI and IP together? We're always looking for
                innovative partnerships and exciting collaborations.
              </p>
              <p className="contact_xbutton">
              <a href="https://x.com/fringe_protocol" target="_blank" rel="noopener noreferrer">
                <svg className='xbutton_footer' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40.424" height="36.726" viewBox="0 0 40.424 36.726"><defs><clipPath id="a"><rect width="40.424" height="36.726" fill="#ebfd36"/></clipPath></defs><g transform="translate(0 0)"><g transform="translate(0 0)" clipPath="url(#a)"><path d="M0,.057C.318.039.509.018.708.018,4.423.016,8.138.027,11.862,0A1.308,1.308,0,0,1,13.033.59q4.233,5.517,8.506,11c.122.157.257.3.441.52.789-.9,1.557-1.755,2.314-2.624q3.893-4.461,7.757-8.93A1.386,1.386,0,0,1,33.25,0c1.355.039,2.71.012,4.246.012L24.2,15.206,40.424,36.667c-.333.018-.561.041-.787.041-3.565,0-7.128-.014-10.692.016a1.412,1.412,0,0,1-1.279-.639q-4.7-6.09-9.431-12.154c-.122-.157-.251-.31-.412-.5-1.192,1.362-2.358,2.691-3.522,4.023q-3.893,4.455-7.784,8.9a.966.966,0,0,1-.6.34c-1.433.03-2.866.016-4.5.016l14.2-16.362Zm7.627,3.9c.195.281.311.462.442.633q3.171,4.172,6.347,8.325,7.327,9.609,14.66,19.216c.143.186.343.456.531.464,1.1.044,2.2.023,3.421.023-.755-.989-1.415-1.856-2.084-2.724L11.441,4.328a.966.966,0,0,0-.647-.352c-1.007-.037-2.015-.018-3.167-.018" transform="translate(0 -0.001)" fill="white"/></g></g></svg>
              </a>
              </p>

              <form className="contact-form">
                <div className="form-group">
                  <input type="text" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <input type="email" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <textarea placeholder="Your Message" required></textarea>
                </div>
                <button type="submit" className="submit-btn">
                  Send Message
                </button>
              </form>
            </div>

            {/*   <div className="phone-model-container">
           
              <Canvas camera={{ position: [0, -7, 0], fov: 45 }}>
                <ambientLight intensity={0.5} />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                <PresentationControls
            global
            config={{ mass: 2, tension: 500 }}
            snap={{ mass: 4, tension: 1500 }}
            rotation={[0, 0.3, 0]}
            polar={[-Math.PI / 3, Math.PI / 3]}
            azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
                </PresentationControls>
                <Physics gravity={[0, -9.81, 0]}>
                  <FallingPhones />
                </Physics>
                <Environment preset="city" />
              </Canvas>
                

            </div> */}
          </div>
        </section>
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-logo-section">
              {/*
      <img 
        src="/green_girl_round.png" 
        alt="Fringe Protocol" 
        className="footer-logo"
      />
      <video 
        className="footer-logo-video"
        autoPlay 
        muted 
        loop 
        playsInline
      >
        <source src="/greenGoddessLogo.mp4" type="video/mp4" />
      </video>
     */}
              <img
                src="/fringe_protocol_white_logo.png"
                alt="Fringe Protocol"
                style={{
                  width: '150px',
                  filter: currentTheme === 'red' ? 'hue-rotate(210deg)' : 'none',
                }}
              />
            </div>

            <div className="footer-social">
              <a href="https://x.com/fringe_protocol" className="social-link" target="_blank" rel="noopener noreferrer">
                <svg className='xbutton_footer' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40.424" height="36.726" viewBox="0 0 40.424 36.726"><defs><clipPath id="a"><rect width="40.424" height="36.726" fill="#ebfd36"/></clipPath></defs><g transform="translate(0 0)"><g transform="translate(0 0)" clipPath="url(#a)"><path d="M0,.057C.318.039.509.018.708.018,4.423.016,8.138.027,11.862,0A1.308,1.308,0,0,1,13.033.59q4.233,5.517,8.506,11c.122.157.257.3.441.52.789-.9,1.557-1.755,2.314-2.624q3.893-4.461,7.757-8.93A1.386,1.386,0,0,1,33.25,0c1.355.039,2.71.012,4.246.012L24.2,15.206,40.424,36.667c-.333.018-.561.041-.787.041-3.565,0-7.128-.014-10.692.016a1.412,1.412,0,0,1-1.279-.639q-4.7-6.09-9.431-12.154c-.122-.157-.251-.31-.412-.5-1.192,1.362-2.358,2.691-3.522,4.023q-3.893,4.455-7.784,8.9a.966.966,0,0,1-.6.34c-1.433.03-2.866.016-4.5.016l14.2-16.362Zm7.627,3.9c.195.281.311.462.442.633q3.171,4.172,6.347,8.325,7.327,9.609,14.66,19.216c.143.186.343.456.531.464,1.1.044,2.2.023,3.421.023-.755-.989-1.415-1.856-2.084-2.724L11.441,4.328a.966.966,0,0,0-.647-.352c-1.007-.037-2.015-.018-3.167-.018" transform="translate(0 -0.001)" fill="white"/></g></g></svg>
              </a>
              <a href="https://t.me/fringeprotocol" className="social-link" target="_blank" rel="noopener noreferrer">
                <Send size={24} />
              </a>
              <a href="#" className="social-link" target="_blank" rel="noopener noreferrer">
                <BookOpen size={24} /><span className="soon-text">soon</span>
              </a>
            </div>

            <div className="footer-copyright">
              <div style={{backgroundColor: 'rgba(0,0,0,0.2)', padding: '0.5rem', borderRadius: '0.5rem', maxWidth: '200px'}}>CA:<span className="soon-text">soon</span></div>
              <div>
                Fringe Protocol © 2025 | made with{' '}
                <span className="footer-love">
                  ❤️ and 💫
                </span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DeSciApp />} />
          <Route path="/DeSci" element={<DeSciApp />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
