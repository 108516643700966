import { useEffect, useRef, useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import { ThemeProvider } from '../context/ThemeContext';
import { useIsMobile } from '../hooks/useIsMobile';

interface Star {
  x: number;
  y: number;
  z: number;
  size: number;
  color: string;
}

// Move colorSchemes outside component
const colorSchemes = {
  green: {
    primary: '#76F434',
    secondary: '#00ffff'
  },
  red: {
    primary: '#ff0059',
    secondary: '#ff9100'
  }
} as const;

function WarpSpeedCanvas({ currentTheme }: { currentTheme: string }) {
  const isMobile = useIsMobile();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const starsRef = useRef<Star[]>([]);
  const mouseRef = useRef({ x: 0, y: 0 });
  const targetMouseRef = useRef({ x: 0, y: 0 });
  const speedRef = useRef(1);
  const lastTouchRef = useRef({ x: 0, y: 0 });

  // Initialize or update stars with correct colors
  const createStar = (radius?: number, angle?: number, z?: number) => {
    const colors = colorSchemes[currentTheme as keyof typeof colorSchemes];
    const newAngle = angle ?? Math.random() * Math.PI * 2;
    const newRadius = radius ?? 50 + Math.random() * 100;
    return {
      x: Math.cos(newAngle) * newRadius,
      y: Math.sin(newAngle) * newRadius,
      z: z ?? Math.random() * 1500 + 500,
      size: Math.random() * 1.5 + 0.5,
      color: Math.random() > 0.5 ? colors.primary : colors.secondary
    };
  };

  // Theme change effect
  useEffect(() => {
    if (!starsRef.current.length) return;
    
    // Update all existing stars with new theme colors
    starsRef.current = starsRef.current.map(star => ({
      ...star,
      ...createStar(Math.hypot(star.x, star.y), Math.atan2(star.y, star.x), star.z)
    }));
  }, [currentTheme]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setCanvasSize = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      mouseRef.current = {
        x: canvas.width / 2,
        y: canvas.height / 2
      };
      targetMouseRef.current = { ...mouseRef.current };
    };
    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    // Initialize stars
    const initStars = () => {
      const stars: Star[] = [];
      for (let i = 0; i < 2000; i++) {
        stars.push(createStar());
      }
      starsRef.current = stars;
    };
    initStars();

    // Simplified touch handler
    const handleTouch = (e: TouchEvent) => {
      e.preventDefault();
      
      const touch = e.touches[0];
      if (!touch) return;

      const rect = canvas.getBoundingClientRect();
      
      // Update target position
      targetMouseRef.current = {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top
      };

      // Calculate speed from touch movement
      if (lastTouchRef.current.x !== 0) {
        const dx = touch.clientX - lastTouchRef.current.x;
        const dy = touch.clientY - lastTouchRef.current.y;
        speedRef.current = 1 + Math.min(Math.hypot(dx / canvas.width, dy / canvas.height) * 8, 3);
      }

      // Update last position
      lastTouchRef.current = {
        x: touch.clientX,
        y: touch.clientY
      };
    };

    // Reset touch position on start
    const handleTouchStart = (e: TouchEvent) => {
      e.preventDefault();
      const touch = e.touches[0];
      if (!touch) return;
      
      lastTouchRef.current = {
        x: touch.clientX,
        y: touch.clientY
      };
    };

    // Mouse handler stays the same
    const handleMouseMove = (e: MouseEvent) => {
      const rect = canvas.getBoundingClientRect();
      targetMouseRef.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      };
      
      speedRef.current = 1 + Math.min(
        Math.hypot(
          e.movementX / canvas.width,
          e.movementY / canvas.height
        ) * 8,
        3
      );
    };

    // Add event listeners
    canvas.addEventListener('touchstart', handleTouchStart);
    canvas.addEventListener('touchmove', handleTouch);
    canvas.addEventListener('mousemove', handleMouseMove);

    const animate = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.15)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const easing = 0.05;
      mouseRef.current.x += (targetMouseRef.current.x - mouseRef.current.x) * easing;
      mouseRef.current.y += (targetMouseRef.current.y - mouseRef.current.y) * easing;

      starsRef.current.forEach((star) => {
        star.z -= speedRef.current * 15;

        if (star.z < 1) {
          // Reset star with new colors when it reaches the end
          const newStar = createStar();
          star.x = newStar.x;
          star.y = newStar.y;
          star.z = 1500;
          star.color = newStar.color;
        }

        const k = 1500 / Math.max(star.z, 1);
        const px = star.x * k + mouseRef.current.x;
        const py = star.y * k + mouseRef.current.y;

        if (px >= 0 && px <= canvas.width && py >= 0 && py <= canvas.height) {
          const size = Math.max((1 - star.z / 1500) * 3, 0.1);
          const opacity = Math.max(1 - star.z / 1500, 0);

          ctx.beginPath();
          ctx.fillStyle = star.color;
          ctx.globalAlpha = opacity;
          ctx.arc(px, py, size, 0, Math.PI * 2);
          ctx.fill();

          if (size > 0.2) {
            ctx.beginPath();
            ctx.strokeStyle = star.color;
            ctx.globalAlpha = opacity * 0.2;
            ctx.lineWidth = size / 3;
            ctx.moveTo(px, py);
            ctx.lineTo(
              px - (star.x * k * 0.2),
              py - (star.y * k * 0.2)
            );
            ctx.stroke();
          }
        }
      });

      requestAnimationFrame(animate);
    };
    animate();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouch);
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, [currentTheme]);

  if (isMobile) {
    return (
      <video
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: 0.8,
          filter: currentTheme === 'red' ? 'hue-rotate(140deg)' : 'none'
        }}
      >
        <source src="/warp.mp4" type="video/mp4" />
      </video>
    );
  }

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%'
      }}
    />
  );
}

interface WarpSpeedProps {
  currentTheme: string;
}

export default function WarpSpeed({ currentTheme }: WarpSpeedProps) {
  return <WarpSpeedCanvas currentTheme={currentTheme} />;
} 