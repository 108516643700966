export const themes = {
  green: {
    primary: '#76F434',
    secondary: '#ff0059',
    magic: 'rgba(0, 255, 0, 0.3)',
    background: '#0a0a0a',
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
    gradient: {
      start: '#76F434',
      end: 'cyan'
    }
  },
  red: {
    primary: '#ff0059',
    secondary: '#76F434',
    magic: 'rgba(255, 0, 89, 0.3)',
    background: '#0a0a0a',
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
    gradient: {
      start: '#ff0059',
      end: 'cyan'
    }
  }
};

export type ThemeType = keyof typeof themes;
export const defaultTheme: ThemeType = 'green'; 