import { useRef, useEffect } from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 100px;
`;

interface MagneticButtonProps {
  className?: string;
  href: string;
  children: React.ReactNode;
}

export default function MagneticButton({ className, href, children }: MagneticButtonProps) {
  const buttonRef = useRef<HTMLDivElement>(null);
  const strength = 50; // Magnetic pull strength
  const magneticArea = 100; // Distance where magnetic effect starts

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) return;

    const handleMouseMove = (e: MouseEvent) => {
      const rect = button.getBoundingClientRect();
      const buttonCenterX = rect.left + rect.width / 2;
      const buttonCenterY = rect.top + rect.height / 2;
      
      const distanceX = e.clientX - buttonCenterX;
      const distanceY = e.clientY - buttonCenterY;
      const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

      if (distance < magneticArea) {
        // Calculate pull based on distance
        const pull = (magneticArea - distance) / magneticArea;
        const moveX = distanceX * pull * (strength / magneticArea);
        const moveY = distanceY * pull * (strength / magneticArea);
        
        button.style.transform = `translate(${moveX}px, ${moveY}px)`;
      } else {
        button.style.transform = 'translate(0, 0)';
      }
    };

    const handleMouseLeave = () => {
      button.style.transform = 'translate(0, 0)';
    };

    document.addEventListener('mousemove', handleMouseMove);
    button.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      button.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <ButtonWrapper ref={buttonRef}>
      <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
        {children}
      </a>
    </ButtonWrapper>
  );
} 