import * as THREE from 'three'
import { Canvas, extend, useFrame } from '@react-three/fiber'
import { useAnimations, MeshPortalMaterial, Environment, OrbitControls, Text, Line, useGLTF, Sphere, MeshTransmissionMaterial, PresentationControls, MeshWobbleMaterial, MeshDistortMaterial, MeshReflectorMaterial } from '@react-three/drei'
import { geometry, easing } from 'maath'
import { useEffect, useRef, useState, useMemo } from 'react'
import { suspend } from 'suspend-react'
import { DepthOfField } from '@react-three/postprocessing'
import { Bloom, EffectComposer, Noise, Vignette } from '@react-three/postprocessing'
import { useTheme } from '../hooks/useTheme'

extend(geometry)
const regular = import('@pmndrs/assets/fonts/inter_regular.woff')

// Camera controller component that responds to scroll
function CameraController({ orbitControlsRef, onScroll }) {
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight
      const normalizedScroll = window.scrollY / maxScroll
      setScrollY(normalizedScroll)
      onScroll?.(normalizedScroll)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [onScroll])

  useFrame(() => {
    if (orbitControlsRef.current) {
      // Only update camera position if user isn't interacting with OrbitControls
      if (!orbitControlsRef.current.isDragging) {
        const targetRotation = THREE.MathUtils.lerp(-Math.PI / 4, Math.PI / 4, scrollY) - 0.2
        orbitControlsRef.current.setAzimuthalAngle(targetRotation)
        orbitControlsRef.current.setPolarAngle(Math.PI / 2.5)
      }
    }
  })

  return null
}

function Hand2({ position = [0, 0, 0], rotation = [0, 0, 0], mirror = false }) {
  const { currentTheme } = useTheme()
  const themeColor = currentTheme === 'green' ? '#76F434' : '#ff0059'
  
  const mesh = new THREE.BoxGeometry(0.2, 0.8, 0.2)
  const material = new THREE.MeshStandardMaterial({
    color: mirror ? themeColor : '#7FFF00',
    roughness: 0.2,
    metalness: 0.8
  })

  return (
    <group position={position} rotation={rotation}>
      <mesh geometry={mesh} material={material} />
      {/* Fingers */}
      <mesh position={[0, 0.4, 0.15]} scale={[0.1, 0.3, 0.1]} material={material}>
        <boxGeometry />
      </mesh>
    </group>
  )
}


function Eye(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/eye.glb')
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions[Object.keys(actions)[0]].play(); // Play the first animation
  }, [actions]);

  
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Eye_Baked_2" rotation={[Math.PI / 2, 0, 0]} scale={2.383}>
                <mesh
                  name="mesh_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.mesh_0.geometry}
                  material={materials.Eye_Ball_Baked}
                  morphTargetDictionary={nodes.mesh_0.morphTargetDictionary}
                  morphTargetInfluences={nodes.mesh_0.morphTargetInfluences}
                />
              </group>
              <group name="Cornea_3" rotation={[1.504, -0.011, 0.156]} scale={2.412}>
                <mesh
                  name="Object_6"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_6.geometry}
                  material={materials.Cornea_Baked}
                />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}


export function Hand(props) {
  const { nodes, materials } = useGLTF('/hand.glb')
  const themeColor = props.currentTheme === 'green' ? '#76F434' : '#ff0059'
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Hand_Mat_0.geometry}
          position={[0, 131, 0]}
          rotation={[0, 0.419, 0]}
        >
          <meshStandardMaterial
            side={THREE.DoubleSide}
            color={props.mirror ? themeColor : '#7FFF00'} 
            emissive={props.mirror ? themeColor : 'cyan'} 
            emissiveIntensity={0.5} 
          />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/hand.glb')


function Portal({currentTheme}) {
  const themeColor = currentTheme === 'green' ? '#76F434' : '#ff0059'
  
  const portalMaterial = new THREE.MeshStandardMaterial({
    color: themeColor,
    metalness: .8,
    roughness: .5
  })

  return (
    <mesh>
      <planeGeometry args={[2, 3]} />
      <MeshPortalMaterial>
        <color attach="background" args={['black']} />
        <Environment preset="night" />

        <Hand currentTheme={themeColor} position={[0.5, 0, -3.5]} rotation={[1.57, THREE.MathUtils.degToRad(90), 0]} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} intensity={1} />
      </MeshPortalMaterial>
      {/* Portal Frame */}
      <mesh position={[0, 0, -0.001]} material={portalMaterial}>
        <ringGeometry args={[0.98, 1.02, 32]} />
      </mesh>
    </mesh>
  )
}

function RandomLines({ count = 50, currentTheme }) {
  const colors = currentTheme === 'green' ? ['lime', 'cyan', 'yellow', 'green'] : ['#ff0059', 'cyan', '#ffffff', '#420099']
  
  // Generate line positions only once
  const lines = useMemo(() => {
    return Array.from({ length: count }, () => ({
      start: [
        THREE.MathUtils.randFloatSpread(20),
        THREE.MathUtils.randFloatSpread(20),
        THREE.MathUtils.randFloatSpread(20)
      ],
      end: [
        THREE.MathUtils.randFloatSpread(20),
        THREE.MathUtils.randFloatSpread(20),
        THREE.MathUtils.randFloatSpread(20)
      ],
      width: Math.random() * 2 + 0.08,
      isDashed: Math.random() > 0.8
    }))
  }, []) // Empty dependency array - only generate once

  // Update colors when theme changes
  const lineColors = useMemo(() => {
    return lines.map(() => colors[Math.floor(Math.random() * colors.length)])
  }, [currentTheme, colors])

  return (
    <group>
      {lines.map((line, i) => (
        <Line
          key={i}
          points={[line.start, line.end]}
          color={lineColors[i]}
          lineWidth={line.width}
          segments={true}
          dashed={line.isDashed}
          opacity={0.5}
          transparent
        />
      ))}
    </group>
  )
}

function WobbleBall({ scrollY, currentTheme }) {
  const ballRef = useRef()
  const themeColor = currentTheme === 'green' ? '#76F434' : '#ff0059'

  useFrame((state) => {
    if (ballRef.current) {
      // Calculate Y position based on scroll (from 4 to -4)
      const targetY = THREE.MathUtils.lerp(8, -20, scrollY)
      ballRef.current.position.y = targetY

      // Add some gentle floating motion
      const time = state.clock.getElapsedTime()
      ballRef.current.position.x = Math.sin(time * 0.5) * 0.5
      ballRef.current.position.z = Math.cos(time * 0.3) * 0.5
    }
  })

  return (
    <group ref={ballRef} position={[0, 10, 0]}>
      <Sphere args={[0.4, 32, 32]}>
        <meshStandardMaterial 
          metalness={0.8} 
          roughness={0.2} 
          color={themeColor} 
          emissive={themeColor} 
          emissiveIntensity={0.5}
        />
      </Sphere>
    </group>
  )
}

function DistortedMesh({currentTheme}) {
  const ref = useRef<any>()
  const themeColor = currentTheme === 'green' ? '#76F434' : '#ff0059'

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.distort = 0.3 + Math.sin(t) * 0.3
  })

  return (
    <mesh scale={[2, 4, 1]}>
      <planeGeometry args={[1, 1, 32, 32]} />
      <MeshDistortMaterial
        ref={ref}
        color={themeColor}
        metalness={0.9}
        roughness={0.2}
        speed={5}
        distort={0.5}
      />
    </mesh>
  )
}

interface MirrorProps {
  currentTheme: string;
}

function Ground({ currentTheme }) {
  const ref = useRef()
  const themeColor = currentTheme === 'green' ? '#76F434' : '#ff0059'

  useFrame((state, delta) => {
    const time = state.clock.getElapsedTime()
    ref.current.color.set(themeColor)
    ref.current.roughness = Math.cos(time) * 0.1 + 0.9
  })

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -2, 0]}>
      <planeGeometry args={[50, 50]} />
      <MeshReflectorMaterial
        blur={[300, 100]}
        resolution={2048}
        mixBlur={1}
        mixStrength={80}
        roughness={1}
        depthScale={1.2}
        minDepthThreshold={0.4}
        maxDepthThreshold={1.4}
        color="#333"
        metalness={0.8}
        ref={ref}
      />
    </mesh>
  )
}

export default function Mirror({ currentTheme }: MirrorProps) {
  const orbitControlsRef = useRef()
  const [scrollProgress, setScrollProgress] = useState(0)

  return (
    <Canvas
      camera={{
        position: [8, 3, 10],
        fov: 45,
        near: 0.1,
        far: 1000
      }}
    >
      <color attach="background" args={['#0a0a0a']} />

      <CameraController
        orbitControlsRef={orbitControlsRef}
        onScroll={setScrollProgress}
      />


      <pointLight position={[10, 10, 10]} intensity={1000} color="#ff0059" />
      <pointLight position={[-10, -10, -10]} intensity={1000} color="cyan" />

      <WobbleBall scrollY={scrollProgress} currentTheme={currentTheme} />
      <PresentationControls
        global
        config={{ mass: 2, tension: 500 }}
        snap={{ mass: 4, tension: 1500 }}
        rotation={[0, 0.3, 0]}
        polar={[-Math.PI / 3, Math.PI / 3]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]} >

        <Portal currentTheme={currentTheme} />
        <Hand currentTheme={currentTheme} position={[0.5, 0, 4]} rotation={[-1.57, Math.PI, 0]} mirror={true} />

        <Text
          font={suspend(regular).default}
          color={currentTheme === 'green' ? '#76F434' : '#ff0059'}
          fontSize={.2}
          position={[-1, 1.6, 0]}
          anchorX="left"
          anchorY="middle"
        >
          FRINGE
        </Text>
      <RandomLines count={100} currentTheme={currentTheme} />

        <Line
        points={[[-10, 1.6, 0], [10, 1.6, 0]]}       // Array of points
        color="cyan"                        // Default
        lineWidth={1}                        // In pixels (default)
        segments={false}                     // If true, renders a THREE.LineSegments2. Otherwise, renders a THREE.Line2
        dashed={false}                       // Default
        vertexColors={[[1, 1, 1], [1, 1, 1]]} // Optional array of RGB values for each point
      />
      <Line
        points={[[-10, -1.6, 0], [10, -1.6, 0]]}       // Array of points
        color="#fe0066"                        // Default
        lineWidth={.1}                        // In pixels (default)
        segments={false}                     // If true, renders a THREE.LineSegments2. Otherwise, renders a THREE.Line2
        dashed={false}                       // Default
        vertexColors={[[1, 1, 1], [1, 1, 1]]} // Optional array of RGB values for each point
      />
      </PresentationControls>

     

      <OrbitControls
        ref={orbitControlsRef}
        enableZoom={false}
        enablePan={false}
        rotateSpeed={0.5}
        dampingFactor={0.1}
        enableDamping={true}
      />

{/*
      <DistortedMesh currentTheme={currentTheme} />
      <Ground currentTheme={currentTheme} />
      */}
      <Environment preset="city" />
    </Canvas>
  )
}