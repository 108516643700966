import * as THREE from 'three'
import { useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { MarchingCubes, MarchingCube, Environment, Bounds } from '@react-three/drei'
import { Physics, RigidBody, BallCollider } from '@react-three/rapier'

interface MetaBallsProps {
  currentTheme: string;
}

function MetaBall({ color, vec = new THREE.Vector3(), ...props }) {
  const api = useRef()
  useFrame((state, delta) => {
    delta = Math.min(delta, 0.1)
    api.current.applyImpulse(
      vec
        .copy(api.current.translation())
        .normalize()
        .multiplyScalar(delta * -0.05),
    )
  })
  return (
    <RigidBody ref={api} colliders={false} linearDamping={4} angularDamping={0.95} {...props}>
      <MarchingCube strength={0.35} subtract={6} color={color} />
      <BallCollider args={[0.1]} type="dynamic" />
    </RigidBody>
  )
}

function Pointer({ vec = new THREE.Vector3(), currentTheme }) {
  const ref = useRef()
  const themeColor = currentTheme === 'green' ? 'lime' : '#ff0059'
  const pointerRef = useRef({ x: 0, y: 0 })
  const touchStartRef = useRef({ x: 0, y: 0 })
  const isScrollingRef = useRef(false)

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      const touch = e.touches[0]
      if (!touch) return

      // Store initial touch position
      touchStartRef.current = {
        x: touch.clientX,
        y: touch.clientY
      }
      isScrollingRef.current = false
    }

    const handleTouch = (e: TouchEvent) => {
      const touch = e.touches[0]
      if (!touch) return

      // Calculate movement delta
      const deltaX = Math.abs(touch.clientX - touchStartRef.current.x)
      const deltaY = Math.abs(touch.clientY - touchStartRef.current.y)

      // If we haven't determined the gesture yet
      if (!isScrollingRef.current) {
        // If movement is more vertical, treat as scroll
        if (deltaY > deltaX && deltaY > 10) {
          isScrollingRef.current = true
          return
        }
        // If movement is more horizontal, prevent scroll
        if (deltaX > deltaY && deltaX > 10) {
          e.preventDefault()
          isScrollingRef.current = false
        }
      }

      // If this is a MetaBalls interaction (not scrolling)
      if (!isScrollingRef.current) {
        e.preventDefault()
        // Convert touch coordinates to normalized coordinates (-1 to 1)
        const x = (touch.clientX / window.innerWidth) * 2 - 1
        const y = -(touch.clientY / window.innerHeight) * 2 + 1
        
        pointerRef.current = { x, y }
      }
    }

    window.addEventListener('touchstart', handleTouchStart, { passive: true })
    window.addEventListener('touchmove', handleTouch, { passive: false })

    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouch)
    }
  }, [])

  useFrame(({ pointer, viewport }) => {
    const { width, height } = viewport.getCurrentViewport()
    const x = pointerRef.current.x || pointer.x
    const y = pointerRef.current.y || pointer.y
    
    vec.set(x * (width / 2), y * (height / 2), 0)
    ref.current.setNextKinematicTranslation(vec)
  })

  return (
    <RigidBody type="kinematicPosition" colliders={false} ref={ref}>
      <MarchingCube strength={0.5} subtract={10} color={themeColor} />
      <BallCollider args={[0.1]} type="dynamic" />
    </RigidBody>
  )
}

export default function MetaBalls({ currentTheme }: MetaBallsProps) {
  return (
    <>
      <Physics gravity={[0, 2, 0]}>
        <MarchingCubes resolution={80} maxPolyCount={20000} enableUvs={false} enableColors>
          <meshStandardMaterial vertexColors thickness={0.15} roughness={0} />
          <MetaBall color="black" position={[1, 1, 0.5]} />
          <MetaBall color="black" position={[-1, -1, -0.5]} />
          <MetaBall color="black" position={[2, 2, 0.5]} />
          <MetaBall color="black" position={[-2, -2, -0.5]} />
          <MetaBall color="black" position={[3, 3, 0.5]} />
          <MetaBall color="black" position={[-3, -3, -0.5]} />
          <Pointer vec={new THREE.Vector3()} currentTheme={currentTheme} />
        </MarchingCubes>
      </Physics>
      <Bounds fit clip observe margin={1}>
        <mesh visible={false}>
          <boxGeometry />
        </mesh>
      </Bounds>
    </>
  )
} 