import { useEffect, useRef } from 'react';
import { Physics, RigidBody, BallCollider } from '@react-three/rapier';
import { MarchingCubes, MarchingCube, Bounds } from '@react-three/drei';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

interface MetaBallsProps {
  currentTheme: string;
}

export default function MetaBallsSensor({ currentTheme }: MetaBallsProps) {
    return (
      <>
        <Physics gravity={[0, 2, 0]}>
          <MarchingCubes 
            resolution={80} // Fixed resolution
            maxPolyCount={20000} // Fixed polygon count
            enableUvs={false} 
            enableColors
          >
            <meshStandardMaterial vertexColors={true} roughness={0} />
            <MetaBall color="black" position={[1, 1, 0.5]} />
            <MetaBall color="black" position={[-1, -1, -0.5]} />
            <MetaBall color="black" position={[2, 2, 0.5]} />
            <MetaBall color="black" position={[-2, -2, -0.5]} />
            <MetaBall color="black" position={[3, 3, 0.5]} />
            <MetaBall color="black" position={[-3, -3, -0.5]} />
            <Pointer vec={new THREE.Vector3()} currentTheme={currentTheme} />
          </MarchingCubes>
        </Physics>
        <Bounds fit clip observe margin={1}>
          <mesh visible={false}>
            <boxGeometry />
          </mesh>
        </Bounds>
      </>
    );
}
  
function MetaBall({ color, vec = new THREE.Vector3(), ...props }) {
    const api = useRef<RigidBody>(null);
    
    useFrame((state, delta) => {
      if (!api.current) return;
      delta = Math.min(delta, 0.1);
      api.current.applyImpulse(
        vec
          .copy(api.current.translation())
          .normalize()
          .multiplyScalar(delta * -0.05),
      );
    });
  
    return (
      <RigidBody ref={api} colliders={false} linearDamping={4} angularDamping={0.95} {...props}>
        <MarchingCube strength={0.35} subtract={6} color={color} />
        <BallCollider args={[0.1]} />
      </RigidBody>
    );
}
  
function Pointer({ vec = new THREE.Vector3(), currentTheme }) {
  const ref = useRef<RigidBody>(null);
  const themeColor = currentTheme === 'green' ? 'lime' : '#ff0059';
  const targetPosition = useRef({ x: 0, y: 0 }); // Target position to move to
  const currentPosition = useRef({ x: 0, y: 0 }); // Current interpolated position

  useFrame(({ pointer, viewport }) => {
    if (!ref.current) return;
    const { width, height } = viewport.getCurrentViewport();
    
    // Update target position from pointer/touch
    targetPosition.current = {
      x: pointer.x,
      y: pointer.y
    };

    // Smoothly interpolate current position towards target
    const lerpFactor = 0.1; // Adjust this value to control smoothing (0-1)
    currentPosition.current = {
      x: currentPosition.current.x + (targetPosition.current.x - currentPosition.current.x) * lerpFactor,
      y: currentPosition.current.y + (targetPosition.current.y - currentPosition.current.y) * lerpFactor
    };
    
    // Use interpolated position to move the ball
    vec.set(
      currentPosition.current.x * (width / 2),
      currentPosition.current.y * (height / 2),
      0
    );
    ref.current.setNextKinematicTranslation(vec);
  });

  return (
    <RigidBody type="kinematicPosition" colliders={false} ref={ref}>
      <MarchingCube strength={0.5} subtract={10} color={themeColor} />
      <BallCollider args={[0.1]} />
    </RigidBody>
  );
}