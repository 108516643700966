import { useLoader } from "@react-three/fiber";
import { TextureLoader } from 'three';
import { forwardRef } from "react";
import { MeshMatcapMaterial } from "three";
import { MeshMatcapMaterialProps } from "@react-three/fiber";

export const CustomMaterial = forwardRef<
  MeshMatcapMaterial,
  MeshMatcapMaterialProps
>((props, ref) => {
  const matcap = useLoader(TextureLoader, "4.jpeg");
  
  return (
    <meshMatcapMaterial
      {...props}
      ref={ref}
      matcap={matcap}
    />
  );
});

CustomMaterial.displayName = 'CustomMaterial';