import { Link } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import MetaBalls from '../components/MetaBalls';
import { useTheme } from '../hooks/useTheme';
import Goddess from '../components/Goddess';
import { Environment, OrbitControls, Sphere } from '@react-three/drei';
import AsciiRenderer from '../components/AsciiRenderer';
import * as THREE from 'three';
import Butterfly from '../components/Butterfly';

export function NotFound() {
  const { currentTheme } = useTheme();

  return (
    <div className="not-found">
      <div className="canvas-container">
        <Canvas
          dpr={[1, 1.5]}
          camera={{ position: [0, 0, 5], fov: 25 }}
          gl={{
            alpha: true,
            antialias: true,
            preserveDrawingBuffer: true
          }}
        >
          <ambientLight intensity={1.5} />
          <directionalLight position={[2, 2, 5]} intensity={1.5} />
          <MetaBalls currentTheme={currentTheme} />
          {/* 
          <AsciiRenderer fgColor="white" bgColor="black" />
           */}
          <Goddess position={[2.4, .25, 0.4]} rotation={[0, -.2, 1.57*2]} scale={0.4} />
          <Butterfly 
            scale={.1}
            position={[0, .23, 0]}
            rotation={[Math.PI/2, 0, 0]} 
          />
          <Environment preset="warehouse"/>
        </Canvas>
      </div>
      
      <div className="not-found-content" style={{ pointerEvents: 'none' }}>
        <h1>404</h1>
        <p>Oops! Looks like you've ventured too far into the fringe.</p>
        <p>This dimension doesn't exist... yet.</p>
        <Link to="/" className="back-home">
          Return to Base Reality
        </Link>
      </div>
    </div>
  );
} 