import { useState, useEffect } from 'react';
import styled from 'styled-components';

const TypewriterText = styled.span`
  font-style: italic;
  font-size: inherit;
  font-family: 'Switzer-Variable', sans-serif;
  font-weight: 600;
  font-variation-settings: 'wght' 600;
  color: var(--theme-primary);
  border-right: 2px solid var(--theme-primary);
  padding-right: 5px;
  animation: blink 0.7s step-end infinite;
  display: inline-block;
  min-height: 1.2em;

  @keyframes blink {
    from, to { border-color: transparent }
    50% { border-color: var(--theme-primary) }
  }
`;

interface TypeWriterProps {
  words: string[];
  typingSpeed?: number;
  deletingSpeed?: number;
  delayBetweenWords?: number;
}

export default function TypeWriter({ 
  words, 
  typingSpeed = 550,
  deletingSpeed = 1000,
  delayBetweenWords = 1000 
}: TypeWriterProps) {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const word = words[currentWordIndex];
    
    const timer = setTimeout(() => {
      if (!isDeleting) {
        // Typing
        if (currentText.length < word.length) {
          setCurrentText(word.slice(0, currentText.length + 1));
        } else {
          // Wait before starting to delete
          setTimeout(() => setIsDeleting(true), delayBetweenWords);
        }
      } else {
        // Deleting
        if (currentText.length > 0) {
          setCurrentText(word.slice(0, currentText.length - 1));
        } else {
          setIsDeleting(false);
          setCurrentWordIndex((prev) => (prev + 1) % words.length);
        }
      }
    }, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, currentWordIndex, words, typingSpeed, deletingSpeed, delayBetweenWords]);

  return <TypewriterText>{currentText || '\u00A0'}</TypewriterText>;
} 