/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 goddess.glb --transform 
Files: goddess.glb [2.12MB] > /Users/jaha/project/FRINGE/public/goddess-transformed.glb [230.22KB] (89%)
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

// Define proper type for props
interface GoddessProps extends THREE.GroupProps {
  // Add any specific props here
}

// Create a custom shader material
const createHologramMaterial = (originalMaterial: THREE.Material) => {
  const baseTexture = originalMaterial instanceof THREE.MeshStandardMaterial 
    ? originalMaterial.map 
    : null;

  return new THREE.ShaderMaterial({
    uniforms: {
      time: { value: 0 },
      baseColor: { value: new THREE.Color('#00ff88').multiplyScalar(0.9) }, // Reduced green intensity
      glowColor: { value: new THREE.Color('#00ff00').multiplyScalar(0.9) }, // Reduced glow intensity
      scanlineSpeed: { value: 1.0 },
      scanlineCount: { value: 10.0 },
      glowIntensity: { value: 0.9 }, // Reduced from 0.5
      transparency: { value: 1 },
      hologramBands: { value: .3 },
      baseTexture: { value: baseTexture },
      hasTexture: { value: baseTexture !== null ? 1.0 : 0.0 },
      textureInfluence: { value: 1.0 },
    },
    vertexShader: `
      uniform float time;
      varying vec2 vUv;
      varying vec3 vNormal;
      varying vec3 vPosition;

      void main() {
        vUv = uv;
        vNormal = normalize(normalMatrix * normal);
        vPosition = position;
        
        // Add subtle floating movement
        vec3 pos = position;
        pos.y += sin(time * 0.5) * 0.02;
        
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      }
    `,
    fragmentShader: `
      uniform vec3 baseColor;
      uniform vec3 glowColor;
      uniform float time;
      uniform float scanlineSpeed;
      uniform float scanlineCount;
      uniform float glowIntensity;
      uniform float transparency;
      uniform float hologramBands;
      uniform sampler2D baseTexture;
      uniform float hasTexture;
      uniform float textureInfluence;
      
      varying vec2 vUv;
      varying vec3 vNormal;
      varying vec3 vPosition;

      void main() {
        // Get original texture color
        vec4 texColor = hasTexture > 0.5 ? texture2D(baseTexture, vUv) : vec4(1.0);
        
        // Scanlines
        float scanline = sin(vUv.y * scanlineCount - time * scanlineSpeed) * 0.1 + 0.9;
        
        // Holographic bands
        float bands = sin(vPosition.y * 20.0 + time) * hologramBands + (1.0 - hologramBands);
        
        // Fresnel effect for edge glow
        float fresnel = pow(1.0 - abs(dot(vNormal, vec3(0.0, 0.0, 1.0))), 2.0);
        
        // Flickering effect
        float flicker = sin(time * 10.0) * 0.05 + 0.95;
        
        // Random glitch effect
        float glitch = step(0.98, sin(time * 100.0 + vPosition.y * 100.0));
        
        // LED point grid effect - modified for smaller points
        vec2 ledGrid = fract(vUv * vec2(100.0, 100.0)); // Increased grid density (was 100.0)
        float led = step(0.1, length(ledGrid - 0.5)) * 0.5 + 0.5; // Increased threshold (was 0.5)
        
        // Modified color combination
        vec3 color = mix(baseColor, glowColor, fresnel * glowIntensity);
        vec3 textureColor = mix(color, texColor.rgb, textureInfluence); // Changed mixing order
        
        // Apply effects
        textureColor *= scanline * bands * flicker * led;
        textureColor += glowColor * glitch * 0.3; // Reduced glitch intensity
        
        // Add some texture-based variation to transparency
        float textureIntensity = (texColor.r + texColor.g + texColor.b) / 3.0;
        float alpha = transparency * (fresnel + 0.5) * bands * (textureIntensity * 0.5 + 0.5);
        
        gl_FragColor = vec4(textureColor, alpha);
      }
    `,
    transparent: true,
    side: THREE.DoubleSide,
  })
}

export default function Goddess(props: GoddessProps) {
  const { nodes } = useGLTF('/goddess-transformed.glb')
  const materialRef = useRef<THREE.ShaderMaterial | null>(null)
  const [materialReady, setMaterialReady] = useState(false)
  const meshRef = useRef<THREE.Mesh>(null)
  useEffect(() => {
    if (nodes.mesh_0.material && !materialRef.current) {
      materialRef.current = createHologramMaterial(nodes.mesh_0.material);
      setMaterialReady(true); // Force a re-render when material is created
    }
  }, [nodes.mesh_0.material])

  useFrame((state) => {
    if (meshRef.current) {
      meshRef.current.rotation.y = Math.sin(state.clock.getElapsedTime() * 0.5) * -0.5;
    if (meshRef.current.position.x > 0.3) {
      const elapsedTime = state.clock.getElapsedTime();
      const speed = Math.max(0.001 - elapsedTime * 0.5, 0.01); // Decrease speed over time
      meshRef.current.position.x -= speed; 
    }
    }
    if (materialRef.current) {
      materialRef.current.uniforms.time.value = state.clock.getElapsedTime();
    }
  })

  // Don't render until material is ready
  if (!materialReady) return null;

  return (
    <group {...props} dispose={null}  ref={meshRef}>
      <mesh
        ref={meshRef}
        geometry={nodes.mesh_0.geometry}
        material={materialRef.current}
      />
    </group>
  )
}

useGLTF.preload('/goddess-transformed.glb')
