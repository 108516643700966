import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import { useGLTF, Environment, OrbitControls } from '@react-three/drei'
import { Suspense, useEffect } from 'react'

function HeadModel(props) {
    const { nodes, materials } = useGLTF('/head.glb')
    return (
      <group {...props} dispose={null}>
        <group scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.face__0.geometry}
           /* material={materials['Scene_-_Root']}*/
            rotation={[-Math.PI / 2, 0, 0]}
            scale={100}
          > 

          <meshStandardMaterial 
            attach="material" 
            color={props.color} 
            metalness={1} 
            roughness={0} 
          />
          </mesh>
        </group>
      </group>
    )
}

export default function Head({ rotation = [0, 0, 0], color = "red" }) {
  useEffect(() => {
    // Find all canvas elements within .image-placeholder
    const placeholders = document.querySelectorAll('.image-placeholder')
    placeholders.forEach(placeholder => {
      // Get the direct div child that wraps the canvas
      const canvasContainer = placeholder.querySelector('div')
      if (canvasContainer) {
        canvasContainer.style.overflow = 'visible'
        canvasContainer.style.position = 'absolute'
        canvasContainer.style.width = '100%'
        canvasContainer.style.height = '100%'
      }
    })
  }, [])

  return (
    <Canvas
    gl={{ alpha: true, antialias: true }}
      camera={{ position: [0, 0, 4], fov: 45 }}
      style={{ 
        width: '100%', 
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'transparent'
      }}
    >
      
      <ambientLight intensity={0.5} />


      <Suspense fallback={null}>
        <HeadModel scale={5} rotation={rotation} position={[-.2, -1, 0]} color={color} />
        <Environment preset="city" />
      </Suspense>
      <Environment preset="city" />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        autoRotate
        autoRotateSpeed={0.5}
      />
    </Canvas>
  )
}
