import { Canvas } from '@react-three/fiber'
import { Suspense } from 'react';
import { Item1 } from './Item1';
import { Item2 } from './Item2';
import { Item3 } from './Item3';
import { Item4 } from './Item4';
import { OrbitControls } from '@react-three/drei';

interface ItemsProps {
  number: number;
}

export const Items = ({ number }: ItemsProps) => {
  const getItem = () => {
    switch (number) {
      case 1:
        return <Item1 />;
      case 2:
        return <Item2 />;
      case 3:
        return <Item3 />;
      case 4:
        return <Item4 />;
      default:
        return <Item1 />; // Default to Item1 if number is invalid
    }
  };

  return (
    <div style={{ width: '100%', height: '200px' }}>
      <Canvas
        camera={{ position: [0, 0, 10], fov: 45 }}
        style={{ background: 'transparent' }}
        gl={{ preserveDrawingBuffer: true }}
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <OrbitControls enableZoom={false} />
        <Suspense fallback={null}>
            <group scale={2.8}>
                {getItem()}
            </group>
        </Suspense>
      </Canvas>
    </div>
  );
};
