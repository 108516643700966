import { Canvas } from '@react-three/fiber';
import { useGLTF, Stage, OrbitControls, useFBX, useAnimations, PresentationControls } from '@react-three/drei';
import { useEffect } from 'react';
import * as THREE from 'three';

function Model() {
  const { scene } = useGLTF('/robot-transformed.glb');
  const animation = useFBX('/WavingRobot.fbx');
  
  const { actions, mixer } = useAnimations(animation.animations, scene);

  useEffect(() => {
    const action = actions[Object.keys(actions)[0]];
    if (action) {
      action.reset().fadeIn(0.5).play();
      action.setLoop(THREE.LoopRepeat, Infinity);
    }
    
    return () => {
      if (action) {
        action.fadeOut(0.5);
      }
      mixer.stopAllAction();
    };
  }, [actions, mixer]);

  return <primitive object={scene} scale={2} />;
}

export function Robot() {
  return (
    <Canvas style={{ height: '60vh' }} camera={{ position: [0, 0, 10], fov: 25 }}>
      <Stage environment="city" intensity={0.6}>
      <PresentationControls
        global
        config={{ mass: 2, tension: 500 }}
        snap={{ mass: 4, tension: 1500 }}
        rotation={[0, -1.4, 0]}
        polar={[-Math.PI / 3, Math.PI / 3]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]} >
          <Model />
        </PresentationControls>
        <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} autoRotate />
      </Stage>
      </Canvas>
  );
}
