import React, { createContext, useContext, useState, useEffect } from 'react';
import { themes, ThemeType, defaultTheme } from '../styles/theme';

interface ThemeContextType {
  currentTheme: ThemeType;
  setTheme: (theme: ThemeType) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>(defaultTheme);

  const setTheme = (theme: ThemeType) => {
    const root = document.documentElement;
    const selectedTheme = themes[theme];

    root.style.setProperty('--theme-primary', selectedTheme.primary);
    root.style.setProperty('--theme-secondary', selectedTheme.secondary);
    root.style.setProperty('--theme-magic', selectedTheme.magic);
    root.style.setProperty('--theme-background', selectedTheme.background);
    root.style.setProperty('--theme-text-primary', selectedTheme.text.primary);
    root.style.setProperty('--theme-text-secondary', selectedTheme.text.secondary);
    root.style.setProperty('--theme-gradient-start', selectedTheme.gradient.start);
    root.style.setProperty('--theme-gradient-end', selectedTheme.gradient.end);

    setCurrentTheme(theme);
  };

  useEffect(() => {
    setTheme(currentTheme);
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ currentTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
} 