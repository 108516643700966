/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 animated_butterfly.glb --transform 
Files: animated_butterfly.glb [1.71MB] > /Users/jaha/project/FRINGE/public/animated_butterfly-transformed.glb [226.36KB] (87%)
Author: eb78 (https://sketchfab.com/E.A.Cornell)
License: CC-BY-SA-4.0 (http://creativecommons.org/licenses/by-sa/4.0/)
Source: https://sketchfab.com/3d-models/ulysses-butterfly-1155effb97564974aa0553db3d5eed57
Title: Ulysses Butterfly
*/

import React, { useEffect, useRef } from 'react'
import { useFrame, useGraph, useThree } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'
import { SkeletonUtils } from 'three-stdlib'
import * as THREE from 'three'

export default function Butterfly(props: any) {
  const group = useRef<THREE.Group>(null)
  const { scene, animations } = useGLTF('/animated_butterfly-transformed.glb')
  
  const clone = React.useMemo(() => SkeletonUtils.clone(scene), [scene])
  const { nodes, materials } = useGraph(clone)

  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    Object.values(materials).forEach(material => {

    material.color = material.color.clone().setHSL(0.33, 1, .5); // Hue rotate to green
    });
    if (actions.fly) {
      actions.fly.play()
    }
  }, [actions])

  const { mouse } = useThree();
  
  useFrame(() => {

    if (group.current) {
      group.current.position.x = (mouse.x * window.innerWidth) / 1200; // Adjust sensitivity as needed
      group.current.position.y = -(mouse.y * window.innerHeight) / -1200; // Adjust sensitivity as needed
    if (group.current) {
      const targetPosition = new THREE.Vector3(mouse.x, mouse.y, 0);
      const worldPosition = new THREE.Vector3();
      group.current.getWorldPosition(worldPosition);
      const direction = targetPosition.sub(worldPosition).normalize();
      const targetQuaternion = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(1, 0, 1), direction);
      group.current.quaternion.slerp(targetQuaternion, 0.1); // Smoothly rotate towards the mouse
    }
    }
  });

  return (
    <group ref={group} {...props} dispose={null} opacity={0.5} transparent={true}>
      <group name="Sketchfab_Scene">
        <primitive object={nodes.GLTF_created_0_rootJoint} />
        <skinnedMesh 
          name="Object_7" 
          geometry={(nodes.Object_7 as THREE.SkinnedMesh).geometry}
          material={materials.UlyssesButterfly_mat}
          skeleton={(nodes.Object_7 as THREE.SkinnedMesh).skeleton}
          scale={0.694} 
        />
      </group>
    </group>
  )
}

useGLTF.preload('/animated_butterfly-transformed.glb')
