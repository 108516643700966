import { Github, Send, Twitter, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactComponent as XLogo } from '/x_logo.svg';
import { Robot } from '../components/Robot';

// Constants
const INITIAL = 10.0;
const GRAVITY = 0.2;
const REBOUND_RATIO = 0.8;
const DAMPING_RATIO = 0.995;

// Types
interface PhysicsObject {
  element: HTMLDivElement;
  width: number;
  height: number;
  px: number;
  py: number;
  vx: number;
  vy: number;
}

interface DOMPhysicsObject extends PhysicsObject {
  originalPosition: { x: number; y: number };
  element: HTMLElement;
}

interface FAQItem {
  question: string;
  answer: string;
}

const faqItems: FAQItem[] = [
  {
    question: "What is Fringe DAO?",
    answer: "Fringe.tech is a community-run DeSci DAO focused on funding research in engineering and applied sciences."
  },
  {
    question: "How does Fringe DAO fund research projects?",
    answer: "The community votes to elect curators who evaluate and approve research proposals. Approved projects receive funding, enabling groundbreaking research in applied sciences."
  },
  {
    question: "Does Fringe DAO have a token?",
    answer: "No, Fringe DAO does not have a token yet. Be careful of scammers claiming otherwise. Always check official links for updates."
  },
  {
    question: "When will the token be released?",
    answer: "Soon."
  }
];

function FAQItem({ question, answer }: FAQItem) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button 
        className={`faq-question ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <ChevronDown className="icon" />
      </button>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        {answer}
      </div>
    </div>
  );
}

export function ComingSoon() {
  let objects: PhysicsObject[] = [];
  let domObjects: DOMPhysicsObject[] = [];
  let isPhysicsEnabled = false;
  let mX = window.innerWidth / 2;
  let mY = window.innerHeight / 2;

  const addObject = (x: number, y: number, w: number, h: number) => {
    const element = document.createElement("div");
    element.style.position = "fixed";
    element.style.left = `${x}px`;
    element.style.top = `${y}px`;
    element.style.width = `${w}px`;
    element.style.height = `${h}px`;
    element.style.borderRadius = "50%";
    element.style.background = "rgba(0, 255, 255, 0.2)";
    element.style.backdropFilter = "blur(8px)";
    element.style.border = "1px solid rgba(0, 255, 255, 0.3)";
    element.style.boxShadow = "0 0 20px rgba(0, 255, 255, 0.3)";
    element.style.zIndex = "1000";
    document.body.appendChild(element);

    const object = {
      element,
      width: w,
      height: h,
      px: x,
      py: y,
      vx: 0,
      vy: 0
    };
    objects.push(object);
    return object;
  };

  const addForce = (obj: PhysicsObject, x: number, y: number) => {
    obj.vx += x;
    obj.vy += y;
  };

  const addDOMElement = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const object: DOMPhysicsObject = {
      element,
      width: rect.width,
      height: rect.height,
      px: rect.left,
      py: rect.top,
      vx: 0,
      vy: 0,
      originalPosition: { x: rect.left, y: rect.top }
    };
    domObjects.push(object);
    
    // Store original styles
    element.dataset.originalPosition = element.style.position;
    element.style.position = 'fixed';
  };

  const updateDOMPhysics = () => {
    if (!isPhysicsEnabled) return;

    domObjects.forEach((obj) => {
      // Apply collisions
      if (obj.px < 0) {
        obj.px = 0;
        obj.vx *= -REBOUND_RATIO;
      } else if (obj.px + obj.width > window.innerWidth) {
        obj.px = window.innerWidth - obj.width;
        obj.vx *= -REBOUND_RATIO;
      }
      if (obj.py < 0) {
        obj.py = 0;
        obj.vy *= -REBOUND_RATIO;
      } else if (obj.py + obj.height > window.innerHeight) {
        obj.py = window.innerHeight - obj.height;
        obj.vy *= -REBOUND_RATIO;
      }

      // Apply physics
      obj.vx *= DAMPING_RATIO;
      obj.vy *= DAMPING_RATIO;
      obj.vy += GRAVITY;
      obj.px += obj.vx;
      obj.py += obj.vy;

      // Update position
      obj.element.style.left = `${obj.px}px`;
      obj.element.style.top = `${obj.py}px`;
    });
  };

  const enablePhysics = () => {
    isPhysicsEnabled = true;
    const elements = document.querySelectorAll('.coming-soon-page *');
    
    elements.forEach((element: HTMLElement) => {
      if (element instanceof HTMLElement && 
          !element.classList.contains('portal-btn') && 
          element.style.display !== 'none') {
        addDOMElement(element);
        // Add random initial velocity
        const lastObj = domObjects[domObjects.length - 1];
        addForce(lastObj, Math.random() * 5 - 2.5, -Math.random() * 10);
      }
    });
  };

  const update = () => {
    objects.forEach((obj, i) => {
      // Apply collisions
      if (obj.px < 0) {
        obj.px = 0;
        obj.vx *= -REBOUND_RATIO;
      } else if (obj.px + obj.width > window.innerWidth) {
        obj.px = window.innerWidth - obj.width;
        obj.vx *= -REBOUND_RATIO;
      }
      if (obj.py < 0) {
        obj.py = 0;
        obj.vy *= -REBOUND_RATIO;
      } else if (obj.py + obj.height > window.innerHeight) {
        obj.py = window.innerHeight - obj.height;
        obj.vy *= -REBOUND_RATIO;
      }

      // Apply physics
      obj.vx *= DAMPING_RATIO;
      obj.vy *= DAMPING_RATIO;
      obj.vy += GRAVITY;
      obj.px += obj.vx;
      obj.py += obj.vy;

      // Update position
      obj.element.style.left = `${obj.px}px`;
      obj.element.style.top = `${obj.py}px`;
    });
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      mX = e.pageX;
      mY = e.pageY;
    };

    const handleClick = (e: MouseEvent) => {
      if ((e.target as HTMLElement).classList.contains('portal-btn')) {
        enablePhysics();
        return;
      }

      const r = Math.random() * 50 + 20;
      const obj = addObject(e.pageX - (r/2), e.pageY - (r/2), r, r);
      addForce(obj, Math.random() * INITIAL * 2 - INITIAL, Math.random() * INITIAL * 2 - INITIAL);
    };

    const timer = setInterval(() => {
      update();
      updateDOMPhysics();
    }, 16);

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('click', handleClick);

    return () => {
      clearInterval(timer);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleClick);
      // Cleanup
      objects.forEach(obj => obj.element.remove());
      objects = [];
      // Restore DOM elements
      domObjects.forEach(obj => {
        obj.element.style.position = obj.element.dataset.originalPosition || '';
        obj.element.style.left = '';
        obj.element.style.top = '';
      });
      domObjects = [];
    };
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Fringe DAO",
    "description": "A community-run DeSci DAO focused on funding research in engineering and applied sciences.",
    "url": "https://fringe.tech",
    "logo": "https://fringe.tech/logo_label.svg",
    "sameAs": [
      "https://t.me/fringetech",
      "https://x.com/FringeDAO",
      "https://app.gitbook.com/o/E3ocCjNTX3JAtobfTDAZ/sites/site_2aEiE"
    ]
  };

  return (
    <div className="coming-soon-page">
      <header className="cs-header">
        <div className="logo">
          <span className="logo-text"><img src={"/fringe_protocol_white_logo.png"} alt="Fringe Logo" /></span>
        </div>
        <button className="portal-btn">
          PORTAL COMING SOON
        </button>
      </header>

      <div className="badge-line">
          <img src={"/greenGoddessLogo.svg"} alt="Fringe Logo" />
          <div className="her-video-wrapper">
            <video className="her-video" src={"/greenGoddessLogo.mp4"} autoPlay loop muted playsInline />
          </div>
      </div>

      <main className="coming-soon-main">
        <div className="hero-content">
          <p className="pre-title">Decentralized Sciences –</p>
          <h1>
            The <span className="highlight">Fringe</span> of<br />
           <span className="highlight">Engineering <span className="round">&</span> Applied Sciences</span>
          </h1>
          <p className="hero-subtitle">
            It all starts with a little curiosity and exploration. Welcome to the future of research.
          </p>
        </div>

        <div className="social-section">
          <div className="social-header">
            <h2>Learn more as we come out of stealth building</h2>
          </div>
          <div className="social-buttons-wrapper">
            <div className="social-buttons">
              <a href="https://t.me/fringetech" className="social-button">
                <Send className="icon" />
                <span>Telegram</span>
              </a>
              <a href="https://x.com/FringeDAO" className="social-button">
                <img src="/x_logo_green.svg" className="icon" alt="X (formerly Twitter)" />
                <span>X / Twitter</span>
              </a>
              <a href="#" className="social-button">
                <Github className="icon" />
                <span>coming soon</span>
              </a>
            </div>
          </div>
        </div>

        <div className="robot-section hide">
          <div className="social-header">
            <h2>Meet Our Research Assistant</h2>
          </div>
          <div className="robot-container">
            <Robot />
          </div>
        </div>

        <div className="faq-section">
          <div className="social-header">
            <h2>FAQ</h2>
          </div>
          <div className="faq-content">
            {faqItems.map((item, index) => (
              <FAQItem key={index} {...item} />
            ))}
          </div>
        </div>

        <footer className="footer">
          <p>Fringe DAO All rights reserved 2025 | made with ❤️ and 💫</p>
        </footer>
      </main>

      <Link 
        to="/DeSci" 
        style={{ 
          position: 'fixed', 
          bottom: '20px', 
          right: '20px', 
          color: 'rgba(255,255,255,0.3)',
          textDecoration: 'none',
          fontSize: '0.8rem',
          display: 'none'
        }}
      >
        View Full Site
      </Link>

      <script 
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    </div>
  );
} 